import { Currency, CurrencyAmount, Fraction, Percent, LOCKTYPE } from '../../sdk'
import React from 'react'
import { Text } from 'rebass'
import { ButtonPrimary } from '../../components/Button'
import { RowBetween, RowFixed } from '../../components/Row'
import CurrencyLogo from '../../components/CurrencyLogo'
import { Field, Metadata } from '../../state/mint/actions'
import { TYPE } from '../../theme'

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  metadata,
  parsedAmounts,
  poolTokenPercentage,
  liqPair,
  commonPair,
  sweepPair,
  lockTime,
  onAdd
}: {
  noLiquidity?: boolean
  commonPair?: boolean
  liqPair?: boolean
  sweepPair?: boolean
  price?: Fraction
  lockTime?: number
  currencies: { [field in Field]?: Currency }
  metadata: { [field in Metadata]?: number }
  parsedAmounts: { [field in Field]?: CurrencyAmount }
  poolTokenPercentage?: Percent
  onAdd: () => void
}) {
  // let sweepable = SWEEPABLE.find(o => o.key === metadata.SWEEPABLE)
  const locktype = LOCKTYPE.find(o => o.key === metadata.LOCKTIME)

  // let sweepableText = typeof sweepable !== 'undefined' ? sweepable.value : ''
  const locktypeText = typeof locktype !== 'undefined' ? locktype.value : ''
  let lockText
  if (!sweepPair) {
    lockText =
      typeof lockTime !== 'undefined' && lockTime !== 0
        ? new Date(lockTime * 1000)
            .toISOString()
            .slice(0, 19)
            .replace('T', ' ')
        : 'Unlocked'
  }
  return (
    <>
      <RowBetween>
        <TYPE.body>{currencies[Field.CURRENCY_A]?.symbol} Deposited</TYPE.body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
          <TYPE.body>{parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}</TYPE.body>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.body>{currencies[Field.CURRENCY_B]?.symbol} Deposited</TYPE.body>
        <RowFixed>
          <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
          <TYPE.body>{parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}</TYPE.body>
        </RowFixed>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Rates</TYPE.body>
        <TYPE.body>
          {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toSignificant(4)} ${
            currencies[Field.CURRENCY_B]?.symbol
          }`}
        </TYPE.body>
      </RowBetween>
      <RowBetween style={{ justifyContent: 'flex-end' }}>
        <TYPE.body>
          {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${price?.invert().toSignificant(4)} ${
            currencies[Field.CURRENCY_A]?.symbol
          }`}
        </TYPE.body>
      </RowBetween>
      <RowBetween>
        <TYPE.body>Share of Pool:</TYPE.body>
        <TYPE.body>{noLiquidity ? '100' : poolTokenPercentage?.toSignificant(4)}%</TYPE.body>
      </RowBetween>
      {noLiquidity && (
        <>
          <RowBetween>
            <TYPE.body>Pair Type:</TYPE.body>
            {commonPair ? <TYPE.body>Standard</TYPE.body> : <></>}
            {liqPair ? <TYPE.body>Liquidity Locked</TYPE.body> : <></>}
            {sweepPair ? <TYPE.body>Sweepable Pair</TYPE.body> : <></>}
            {/* <TYPE.body>{sweepableText}</TYPE.body> */}
          </RowBetween>

          {(metadata.SWEEPABLE === 1 || liqPair || sweepPair) && (
            <RowBetween>
              <TYPE.body>Locked:</TYPE.body>
              <TYPE.body>{locktypeText ? <> {locktypeText} </> : <>{lockText} </>}</TYPE.body>
            </RowBetween>
          )}
        </>
      )}
      <ButtonPrimary style={{ margin: '20px 0 0 0' }} onClick={onAdd}>
        <Text fontWeight={500} fontSize={20}>
          {noLiquidity ? 'Create Pool & Supply' : 'Confirm Supply'}
        </Text>
      </ButtonPrimary>
    </>
  )
}

import React, { useCallback } from 'react'
import styled from 'styled-components'
import useENS from '../../hooks/useENS'
import { useActiveWeb3React } from '../../hooks'
import { ExternalLink } from '../../theme'
import { AutoColumn } from '../Column'
// import { RowBetween } from '../Row'
import { getExplorerLink } from '../../utils'

const InputPanel = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background-color: transparent;
  border: 1px none;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: auto;
  mix-blend-mode: normal;
  position: relative;
`

const ContainerRow = styled.div<{ error: boolean }>`
  align-items: flex-start;
  align-self: stretch;
  background-color: #999;
  border: 1px solid;
  border-color: transparent;
  border-radius: 12px;
  box-shadow: inset 0px 1px 0px #00000040;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: auto;
  mix-blend-mode: normal;
  padding: 16px;
  position: relative;
`

const InputContainer = styled.div`
  flex: 1;
  padding: 1rem;
`

const Input = styled.input<{ error?: boolean }>`
  background-color: transparent;
  flex: 1;
  font-family: 'Roboto Mono', Helvetica;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28.8px;
  margin-top: -1px;
  mix-blend-mode: normal;
  position: relative;
  text-align: left;
  white-space: nowrap;
  border: none;

  outline: none;
  transition: color 300ms ${({ error }) => (error ? 'step-end' : 'step-start')};
  color: ${({ error, theme }) => (error ? theme.red1 : '#ffffff')};

  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
  padding: 0px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.text4};
  }
`

const StyledTextWrap = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background-color: transparent;
  border: 1px none;
  display: flex;
  height: auto;
  justify-content: space-between;
  position: relative;
`

const StyledTextLeft = styled.div`
  background-color: transparent;
  letter-spacing: 0px;
  line-height: 14px;
  margin-top: -1px;
  mix-blend-mode: normal;
  position: relative;
  text-align: left;
  white-space: nowrap;
  width: fit-content;

  color: #fff;
  font-family: 'Gordita-Medium', Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`

const StyledTextRight = styled.div`
  background-color: transparent;
  letter-spacing: 0px;
  line-height: 12px;
  margin-top: -1px;
  mix-blend-mode: normal;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;

  color: #71718e;
  font-family: 'Roboto Mono', Helvetica;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
`

export default function AddressInputPanel({
  id,
  value,
  onChange
}: {
  id?: string
  // the typed string value
  value: string
  // triggers whenever the typed value changes
  onChange: (value: string) => void
}) {
  const { chainId } = useActiveWeb3React()
  // const theme = useContext(ThemeContext)

  const { address, loading, name } = useENS(value)

  const handleInput = useCallback(
    event => {
      const input = event.target.value
      const withoutSpaces = input.replace(/\s+/g, '')
      onChange(withoutSpaces)
    },
    [onChange]
  )

  const error = Boolean(value.length > 0 && !loading && !address)

  return (
    <InputPanel id={id}>
      <StyledTextWrap>
        <StyledTextLeft>Recipient</StyledTextLeft>

        <StyledTextRight>
          {address && chainId && (
            <ExternalLink href={getExplorerLink(chainId, name ?? address, 'address')} style={{ fontSize: '14px' }}>
              (View on Explorer)
            </ExternalLink>
          )}
        </StyledTextRight>
      </StyledTextWrap>
      <ContainerRow error={error}>
        <InputContainer>
          <AutoColumn gap="md">
            <Input
              className="recipient-address-input"
              type="text"
              autoComplete="off"
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              placeholder="Wallet Address or ENS name"
              error={error}
              pattern="^(0x[a-fA-F0-9]{40})$"
              onChange={handleInput}
              value={value}
            />
          </AutoColumn>
        </InputContainer>
      </ContainerRow>
    </InputPanel>
  )
}

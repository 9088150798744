import React from 'react'
import styled from 'styled-components'

// align-items: center;
//
//
//
//
//
//
// display: flex;
// flex-direction: column;
// height: auto;
// left: 506px;
//
// position: absolute;
// top: 152px;
// width: 500px;

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  background: #0d0e10;
  border: 1px solid #007050;
  box-shadow: 0px 13px 64px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  mix-blend-mode: normal;
  border: 1px solid #007050;
  color: #fff;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
